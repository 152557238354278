<template>
	<div>
        <h2>{{ $t("monte.historique_avenant") }}</h2>
				
		<div class="box">
			<CustomTable
				id_table="contract_historique"
				:items="filtredContract"
				:busy="table_busy"
				primaryKey="avenant_id"
				:hide_if_empty="true"
				:rawColumns="rawColumnNames"
				:hrefsRoutes="config_table_hrefs"
			/>
		</div>
	</div>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import TableAction from "@/mixins/TableAction.js"
	import Navigation from "@/mixins/Navigation.js"
	import ContractMixin from "@/mixins/Contract.js"
	import Model from "@/mixins/Model.js"

	import TiersMixin from "@/mixins/Tiers.js"
	import AccountingMixin from "@/mixins/Accounting.js"
	import AccountingAccountMixin from "@/mixins/AccountingAccount.js"
	import ArticleMixin from "@/mixins/Article.js"
	import Syndic from "@/mixins/Syndic.js"

	export default {
		name: "ContractMareList",
		mixins: [TableAction, Navigation, ContractMixin, Model, TiersMixin, AccountingMixin, AccountingAccountMixin, ArticleMixin, Syndic],
		props: ['horse', 'contract_id'],
		data () {
			return {
				table_busy: false,
				table: true,
				contracts: [],
				
				rawColumnNames: [
					"articles"
				],
				config_table_hrefs: {
					'contract.contract_tiers[0].tiers.tiers_rs': {
						routeUniqueName: 'tiersTableauBord',
						routeName: 'tiersTableauBord',
						params: {
							tiers_id: 'contract.contract_tiers.0.tiers.tiers_id'
						}
					},
					'cmep.tiers_rs': {
						routeUniqueName: 'cmepTableauBord',
						routeName: 'tiersTableauBord',
						params: {
							tiers_id: 'cmep.tiers_id'
						}
					},
					'horse.horse_nom': {
						routeUniqueName: 'MareFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'horse.horse_id'
						}
					},
					'contract.config.horse.horse_nom': {
						routeUniqueName: 'StallionFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'contract.config.horse.horse_id'
						}
					}
				}
			}
		},
		mounted() {
			this.init_component()
			EventBus.$on("Swipper::TabClicked", (params) => {
				if(params.href == "ContractMareList" && !this.table_busy)
				{
					this.table = true
					this.init_component()
				}
			})

			EventBus.$on("ContractMareList::reloadTabs", (params) => {
				if(params.href == "ContractMareList" && !this.table_busy)
				{
					this.table = true
					this.init_component()
				}
			})
		},
		methods: {
			async init_component() {
				this.table_busy = true
				this.table = true

				this.contracts = await this.getAllAvenant(this.contract_id)

				this.table_busy = false
			},
			async checkForm() {
				if(!this.processing) {
					this.processing = true
				}
			},
			
			back_action(){
				this.init_component()
			},
		},
		computed: {
			filtredContract: function() {
				let tab = []

				for (let i = 0; i < this.contracts.length; i++) {
					let temp = this.contracts[i]
					tab.push(temp)
				}

				return tab
			}
		},
		watch: {
			
		},
		components: {
			HeaderTab: () => import('@/components/HeaderTab'),
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ContractForm : () => import('@/components/Contract/ContractForm'),
			ContractShutter : () => import('@/components/Contract/ContractShutter'),
		},
	}
</script>
